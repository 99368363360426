import type { ToastSettings } from '@skeletonlabs/skeleton';

//const API_URL = "https://script.google.com/macros/s/AKfycbzNEMVclp25Gd77nR5rQG7vs6ouL6rTlZLohbot_gA8ugjQRWY7IiVqaJHdq14LDSmvwg/exec";
//const API_URL = "/api";


export function formatTimestamp(milliseconds, format) {
  format = format || "H:i:s";

  const H = Math.floor(milliseconds / 60 / 60 / 1000);
  milliseconds -= H * 60 * 60 * 1000;
  const i = Math.floor(milliseconds / 60 / 1000);
  milliseconds -= i * 60 * 1000;
  const s = Math.floor(milliseconds / 1000);
  milliseconds -= s * 1000;

  return format
    .replace("H", H.toString().padStart(2, "0"))
    .replace("i", i.toString().padStart(2, "0"))
    .replace("s", s.toString().padStart(2, "0"));
}

export function htmlEncode(s) {
  return s.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&#34;');
}

export const ghostTypes = {
  "Banshee": "Banshee",
  "Dämon": "Dämon",
  "Demon": "Dämon",
  "Deogen": "Deogen",
  "Die Zwillinge": "Die Zwillinge",
  "Dschinn": "Dschinn",
  "Gespenst": "Gespenst",
  "Goryo": "Goryo",
  "Hantu": "Hantu",
  "Jinn": "Dschinn",
  "Mare": "Mare",
  "Mimik": "Mimik",
  "Moroi": "Moroi",
  "Myling": "Myling",
  "Obake": "Obake",
  "Oni": "Oni",
  "Onryo": "Onryo",
  "Phantom": "Phantom",
  "Poltergeist": "Poltergeist",
  "Raiju": "Raiju",
  "Revenant": "Revenant",
  "Shade": "Shade",
  "Spirit": "Spirit",
  "Thaye": "Thaye",
  "The Mimic": "Mimik",
  "The Twins": "Die Zwillinge",
  "Wraith": "Gespenst",
  "Yokai": "Yokai",
  "Yurei": "Yurei",
};

export function error(msg, toastStore) {
  if (!toastStore) return;
  const t: ToastSettings = {
    message: msg,
    background: 'variant-filled-error',
    timeout: 5000,
    hoverable: true
  };
  toastStore.trigger(t);
}
export function info(msg, toastStore) {
  if (!toastStore) return;
  const t: ToastSettings = {
    message: msg,
    background: 'variant-filled-tertiary',
    timeout: 5000,
    hoverable: true
  };
  toastStore.trigger(t);
}

let socket = undefined;
const responseHandlers = [];
const requestHandlers = [];
let socketId = 0;
function disconnectApi() {
  socket.onclose = null;
  socket.close();
}
function connectApi() {
  switch (socket ? socket.readyState : WebSocket.CLOSED) {
    case WebSocket.CONNECTING:
      return new Promise((resolve) => {
        socket.addEventListener("open", resolve);
      });
    case WebSocket.OPEN:
      return Promise.resolve();
    default: break;
  }

  socket = new WebSocket(`${window.location.protocol == "https:" ? "wss" : "ws"}://${window.location.host}/api`);
  socket.onclose = () => {
    setTimeout(connectApi, 1000);
  };
  socket.onerror = (e) => {
    console.error(e);
    socket.close();
  };
  function runResponseHandlers(message) {
    const responseHandler = responseHandlers.find(p => p[0] == message.id);
    if (!responseHandler) return;

    const [, resolve, reject, toastStore, errorfn] = responseHandler;
    if (message.error) {
      if (!errorfn || !errorfn(message)) {
        error(`API Error (${message.error.message})`, toastStore);
      }
      return reject(new Error(message.error.message));
    }

    resolve(message.result);
  }
  function runRequestHandlers(message) {
    requestHandlers.filter(p => p[0] == message.method).forEach(requestHandler => {
      const [, callback] = requestHandler;
      callback(message);
    });
  }
  socket.onmessage = (raw) => {
    const message = JSON.parse(raw.data);
    if (!message) return;

    runResponseHandlers(message);
    runRequestHandlers(message);
  };

  return new Promise((resolve) => {
    socket.addEventListener("open", resolve);
  });
}
export async function apiHandler(method, callback) {
  requestHandlers.push([method, callback]);
}
export async function api(method, params, toastStore, errorfn) {
  await connectApi();
  const id = ++socketId;
  const promise = new Promise((resolve, reject) => {
    responseHandlers.push([id, resolve, reject, toastStore, errorfn]);
    socket.send(JSON.stringify({jsonrpc: "2.0", method, params, id}));
  });
  return promise;
  /*let response = await fetch(`${API_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({jsonrpc: "2.0", method, params})
  });

  response = response && await response.json();
  if (response && response.error) {
    if (!errorfn || !errorfn(response)) {
      error(`API Error (${response.error.message})`, toastStore);
    }
    return null;
  }

  return response.result;*/
}

export const TWITCH_REDIRECT_URL = `${window.location.protocol}//${window.location.host}/auth`;

export function login() {
  window.location.href = `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=f94gkq1m8mxx56u76crrsfzhm8lohh&redirect_uri=${TWITCH_REDIRECT_URL}`;
}
export function logout(reload) {
  api("logout").then(() => {
    if (reload || window.location.pathname != "/") window.location = "/";
    disconnectApi();
  });
}

let leaderboardDataInterval = null;
export async function updateLeaderboardData(id, cb, auto) {
  if (!auto && leaderboardDataInterval) clearInterval(leaderboardDataInterval);
  if (!auto) leaderboardDataInterval = setInterval(() => updateLeaderboardData(id, cb,  true), 10000);
  cb(await api("leaderboard.get", id) || []);
}